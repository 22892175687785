export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"},{"charset":"utf-8"},{"name":"google-site-verification","content":"8C-e9B09Htp6a4zLq94UDZ-qMUgLJRbauVEcP_5tARo"}],"link":[{"rel":"icon","href":"/faviconSW.ico"}],"style":[],"script":[{"src":"/js/jsencrypt.min.js"},{"src":"/js/browser.js"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no","title":""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null